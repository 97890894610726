import React from "react";
import { AppBar } from "react-admin";
import MyUserMenu from "./MyUserMenu";
import ShopName from "./ShopName";
import logo from "../../logo/expiryTrackerDates.svg";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { usePermissions } from "react-admin";
// import IconButton from "@material-ui/core/IconButton";
// import HelpIcon from "@material-ui/icons/HelpOutline";

const styles = {
  spacer: {
    flex: 1,
  },
  link: {
    display: "flex",
    alignItems: "center",
    textDecoration: "initial",
  },
};

const MyAppBar = ({ classes, ...props }) => {
  const { permissions } = usePermissions();

  return (
    <AppBar
      {...props}
      userMenu={<MyUserMenu />}
      variant="elevation"
      elevation={1}
    >
      <Link className={classes.link} to="/">
        <img
          // width="134"
          height="27"
          src={logo}
          alt="logo"
          style={{ paddingLeft: 10, filter: "brightness(84%)" }}
        ></img>
        <span id="ExpiryTracker">ExpiryTracker</span>
        {permissions &&
        permissions.chainId &&
        permissions.shopId &&
        permissions.role ? (
          <ShopName
            chainId={permissions.chainId}
            shopId={permissions.shopId}
            role={permissions.role}
          />
        ) : null}
      </Link>
      <span className={classes.spacer} />
      {/* <IconButton color="inherit">
        <HelpIcon />
      </IconButton> */}
    </AppBar>
  );
};
export default withStyles(styles)(MyAppBar);
