import * as React from "react";
import { Edit, SimpleForm, TextInput } from "react-admin";

const ChainEdit = (props) => (
  <Edit {...props}>
    <SimpleForm variant="standard">
      <TextInput disabled source="id" />
      <TextInput source="note" />
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
);

export default ChainEdit;
