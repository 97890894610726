import React, { useState, useEffect } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  SaveButton,
  Toolbar,
  required,
  useDataProvider,
  // Error,
} from "react-admin";
import { useFormState } from "react-final-form";

const ShopInput = (props) => {
  const { values } = useFormState();

  const dataProvider = useDataProvider();
  const [shops, setShops] = useState([]);
  const [error, setError] = useState();
  useEffect(() => {
    let isMounted = true;
    if (values.customClaims && values.customClaims.chainId) {
      dataProvider
        .getList("shops", {
          pagination: { page: 1, perPage: 10 },
          sort: { field: "id", order: "desc" },
          filter: { chainId: values.customClaims.chainId },
        })
        .then(({ data }) => {
          if (isMounted) {
            setShops(data);
          }
        })
        .catch((error) => {
          if (isMounted) {
            setError(error);
          }
        });
    }
    return () => {
      isMounted = false;
    };
  }, [values, dataProvider]);

  if (error) {
    console.log(error);
  }

  const toChoices = (shops) => {
    return shops.map((shop) => ({
      id: shop.id,
      name: shop.name,
    }));
  };

  return (
    <SelectInput choices={shops.length ? toChoices(shops) : []} {...props} />
  );
};

const UserEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const UserEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm variant="standard" toolbar={<UserEditToolbar />}>
        <TextInput
          source="customClaims.name"
          label="Name"
          validate={[required()]}
        />
        <SelectInput
          source="customClaims.role"
          label="Role"
          validate={[required()]}
          choices={[
            { id: "user", name: "User" },
            { id: "moderator", name: "Moderator" },
            { id: "admin", name: "Admin" },
          ]}
        />
        <TextInput disabled label="Id" source="id" />
        <TextInput disabled source="email" />
        <TextInput disabled label="Created" source="createdAt" />
        <ReferenceInput
          label="Chain"
          source="customClaims.chainId"
          reference="chains"
          validate={[required()]}
        >
          <SelectInput />
        </ReferenceInput>
        <ShopInput
          label="Shop"
          source="customClaims.shopId"
          validate={[required()]}
        />
      </SimpleForm>
    </Edit>
  );
};

export default UserEdit;
