import React from "react";
import { Admin, Resource } from "react-admin";
import ItemList from "./components/items/ItemList";
import ItemCreate from "./components/items/ItemCreate";
import ItemEdit from "./components/items/ItemEdit";
import UserList from "./components/users/UserList";
import UserEdit from "./components/users/UserEdit";
import ShopList from "./components/shops/ShopList";
import ShopEdit from "./components/shops/ShopEdit";
import ShopCreate from "./components/shops/ShopCreate";
import SectionList from "./components/sections/SectionList";
import SectionEdit from "./components/sections/SectionEdit";
import SectionCreate from "./components/sections/SectionCreate";
import ChainList from "./components/chains/ChainList";
import ChainEdit from "./components/chains/ChainEdit";
import ChainCreate from "./components/chains/ChainCreate";
import { dataProvider, authProvider } from "./firebaseProviders";
import theme from "./theme";
import profile from "./profile";
import { Route } from "react-router-dom";
import MyLayout from "./components/layout/MyLayout";
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";
// domain translations
import domainMessages from "./i18n";
import MyLoginPage from "./components/MyLoginPage";
import expiryTrackerReducer from "./reducers/expiryTrackerReducer";

const messages = {
  ra: {
    ...englishMessages.ra,
    message: { ...englishMessages.ra.message, ...domainMessages.en.ra.message },
  },
};
const i18nProvider = polyglotI18nProvider(() => messages, "en", {
  allowMissing: true,
});

// https://stackoverflow.com/questions/63961087/how-to-get-theme-from-store-and-switch-the-app-theme-on-react-admin

function App() {
  return (
    <Admin
      customReducers={{ eT: expiryTrackerReducer }}
      disableTelemetry
      title="Expiry Tracker"
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      theme={theme}
      loginPage={MyLoginPage}
      customRoutes={[
        <Route key="my-profile" path="/my-profile" component={profile.edit} />,
      ]}
      layout={MyLayout}
    >
      {(permissions) => [
        <Resource
          options={{ label: `Items` }}
          name="items"
          list={ItemList}
          edit={ItemEdit}
          create={ItemCreate}
        />,
        <Resource name="profile" />,
        // Only include the users and the shops resources for admin users
        permissions.role === "admin" ? (
          <Resource name="users" list={UserList} edit={UserEdit} />
        ) : null,
        permissions.role === "admin" ? (
          <Resource
            name="shops"
            list={ShopList}
            edit={ShopEdit}
            create={ShopCreate}
          />
        ) : null,
        permissions.role === "admin" || permissions.role === "dev" ? (
          <Resource
            name="chains"
            list={ChainList}
            edit={ChainEdit}
            create={ChainCreate}
          />
        ) : null,
        <Resource
          name="sections"
          list={
            permissions.role === "admin" || permissions.role === "moderator"
              ? SectionList
              : null
          }
          edit={
            permissions.role === "admin" || permissions.role === "moderator"
              ? SectionEdit
              : null
          }
          create={
            permissions.role === "admin" || permissions.role === "moderator"
              ? SectionCreate
              : null
          }
        />,
      ]}
    </Admin>
  );
}

export default App;
