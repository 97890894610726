// in src/MyMenu.js
import React from "react";
import { connect } from "react-redux";
import { MenuItemLink, getResources, usePermissions } from "react-admin";
import { withRouter } from "react-router-dom";
import UserIcon from "@material-ui/icons/Group";
import CategoryIcon from "@material-ui/icons/Category";
import StoreIcon from "@material-ui/icons/Store";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

const MyMenu = ({ resources, onMenuClick }) => {
  const { permissions } = usePermissions();

  return (
    <div>
      {permissions &&
      (permissions.role === "admin" || permissions.role === "moderator") ? (
        <>
          <MenuItemLink
            activeClassName="menuActive"
            leftIcon={<FormatListBulletedIcon />}
            to="/items"
            primaryText="Items"
            onClick={onMenuClick}
          />
        </>
      ) : null}

      {permissions &&
      (permissions.role === "admin" || permissions.role === "dev") ? (
        <>
          <MenuItemLink
            activeClassName="menuActive"
            leftIcon={<ShoppingCartIcon />}
            to="/chains"
            primaryText="Chains"
            onClick={onMenuClick}
          />
          <MenuItemLink
            activeClassName="menuActive"
            leftIcon={<StoreIcon />}
            to="/shops"
            primaryText="Shops"
            onClick={onMenuClick}
          />
        </>
      ) : null}
      {permissions &&
      (permissions.role === "moderator" || permissions.role === "dev") ? (
        <>
          <MenuItemLink
            activeClassName="menuActive"
            leftIcon={<CategoryIcon />}
            to="/sections"
            primaryText="Sections"
            onClick={onMenuClick}
          />
        </>
      ) : null}
      {permissions &&
      (permissions.role === "admin" || permissions.role === "dev") ? (
        <>
          <MenuItemLink
            activeClassName="menuActive"
            leftIcon={<UserIcon />}
            to="/users"
            primaryText="Users"
            onClick={onMenuClick}
          />
        </>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  resources: getResources(state),
});

export default withRouter(connect(mapStateToProps)(MyMenu));
