import React from "react";
import { useRecordContext } from "react-admin";

const TimeStatuses = (props) => {
  const record = useRecordContext(props);

  if (
    !record ||
    !record.createdate ||
    !(record.createdate instanceof Date) ||
    isNaN(record.createdate)
  )
    return null;

  const options = {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
  };
  const statuses = [];
  statuses.push(
    <span key="created" className="status created">
      Added {record.createdate.toLocaleDateString(undefined, options)}
    </span>
  );

  if (
    record.reduced &&
    record.reducedat &&
    record.reducedat instanceof Date &&
    !isNaN(record.reducedat)
  ) {
    statuses.push(
      <span key="reduced" className="status reduced">
        Reduced {record.reducedat.toLocaleDateString(undefined, options)}
      </span>
    );
  }
  if (
    record.sold &&
    record.soldat &&
    record.soldat instanceof Date &&
    !isNaN(record.soldat)
  ) {
    statuses.push(
      <span key="sold" className="status sold">
        Sold {record.soldat.toLocaleDateString(undefined, options)}
      </span>
    );
  }

  return <p className="statuses">{statuses}</p>;
};

export default TimeStatuses;
