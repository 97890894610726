import React from "react";
import Avatar from "@material-ui/core/Avatar";

const AvatarWrapper = ({ record, source }) => {
  return (
    <Avatar
      variant="rounded"
      style={{ width: 90, height: 90, marginRight: 10 }}
      alt={record ? record.name : ""}
      src={
        record && record[source] && record[source].src ? record[source].src : ""
      }
      imgProps={{ height: "90px", width: "90px" }}
    />
  );
};

export default AvatarWrapper;
