import React from "react";
import { useGetList, useListContext } from "react-admin";
import { useMediaQuery } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";

import { useSelector, useDispatch } from "react-redux";

const FilterTabs = ({ permissions }) => {
  // const [value, setValue] = React.useState(3);
  const value = useSelector((state) => state.eT.tab);
  const dispatch = useDispatch();
  const { filterValues, setFilters, displayedFilters } = useListContext();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const cleanFilters = (filterValues, setShopId = false) => {
    const { sold, reduced, expiring, expired, ...filterValuesRest } =
      filterValues;
    if (setShopId) {
      filterValuesRest.shopId = permissions && permissions.shopId;
    }
    return filterValuesRest;
  };

  // TODO: Use Redux
  const useGetTotals = (filterValues) => {
    const filterValuesCleaned = cleanFilters(filterValues, true);
    const { total: totalAll } = useGetList(
      "items",
      { perPage: 1, page: 1 },
      { field: "id", order: "ASC" },
      { ...filterValuesCleaned }
    );
    const { total: totalToDo } = useGetList(
      "items",
      { perPage: 1, page: 1 },
      { field: "id", order: "ASC" },
      { ...filterValuesCleaned, reduced: false, sold: false, expiring: true }
    );
    const { total: totalSold } = useGetList(
      "items",
      { perPage: 1, page: 1 },
      { field: "id", order: "ASC" },
      { ...filterValuesCleaned, sold: true }
    );
    const { total: totalReduced } = useGetList(
      "items",
      { perPage: 1, page: 1 },
      { field: "id", order: "ASC" },
      { ...filterValuesCleaned, reduced: true }
    );

    return {
      all: totalAll,
      todo: totalToDo,
      sold: totalSold,
      reduced: totalReduced,
    };
  };
  const totals = useGetTotals(filterValues);

  const handleChange = (event, value) => {
    dispatch({ type: "TAB_SET", payload: { tab: value } });
    // setValue(value);
    switch (value) {
      case 1:
        // Reduced
        if (setFilters) {
          const filterValuesCleaned = cleanFilters(filterValues);
          setFilters(
            {
              ...filterValuesCleaned,
              reduced: true,
            },
            displayedFilters
          );
        }
        break;
      case 2:
        // Sold
        if (setFilters) {
          const filterValuesCleaned = cleanFilters(filterValues);
          setFilters(
            {
              ...filterValuesCleaned,
              sold: true,
            },
            displayedFilters
          );
        }
        break;
      case 3:
        // All
        if (setFilters) {
          const filterValuesCleaned = cleanFilters(filterValues);
          setFilters(
            {
              ...filterValuesCleaned,
            },
            displayedFilters
          );
        }
        break;
      default:
        // To do
        if (setFilters) {
          const filterValuesCleaned = cleanFilters(filterValues);
          setFilters(
            {
              ...filterValuesCleaned,
              reduced: false,
              sold: false,
              expiring: true,
            },
            displayedFilters
          );
        }
        break;
    }
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      // "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  // console.log(counter);

  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        TabIndicatorProps={{ id: "indicator" }}
        style={
          isSmall
            ? { borderBottom: "0" }
            : { borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }
        }
      >
        <Tab
          disabled={totals.todo === 0 ? true : false}
          label={
            totals.todo ? (
              <div className="chip">
                <span>Expiring</span>
                <Chip label={totals.todo} size="small" />
              </div>
            ) : (
              `Expiring`
            )
          }
          {...a11yProps(0)}
        />
        <Tab
          disabled={totals.reduced === 0 ? true : false}
          label={
            totals.reduced ? (
              <div className="chip">
                <span>Reduced</span>
                <Chip label={totals.reduced} size="small" />
              </div>
            ) : (
              `Reduced`
            )
          }
          {...a11yProps(1)}
        />
        <Tab
          disabled={totals.sold === 0 ? true : false}
          label={
            totals.sold ? (
              <div className="chip">
                <span>Sold</span>
                <Chip label={totals.sold} size="small" />
              </div>
            ) : (
              `Sold`
            )
          }
          {...a11yProps(2)}
        />
        <Tab
          label={
            totals.all ? (
              <div className="chip">
                <span>All</span>
                <Chip label={totals.all} size="small" />
              </div>
            ) : (
              `All`
            )
          }
          {...a11yProps(3)}
        />
      </Tabs>
    </Box>
  );
};

export default FilterTabs;
