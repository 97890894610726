import React from "react";
import { useRecordContext } from "react-admin";
const SectionWrapper = (props) => {
  const record = useRecordContext(props);
  return (
    <span style={{ borderBottom: "3px solid", borderColor: record.color }}>
      {record && record.name ? record.name : "unknown"}
    </span>
  );
};

export default SectionWrapper;
