import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  required,
} from "react-admin";

const ShopEdit = (props) => (
  <Edit {...props}>
    <SimpleForm variant="standard">
      <TextInput disabled source="id" />
      <TextInput source="name" />
      <TextInput source="address" />
      <ReferenceInput
        label="Chain"
        source="chainId"
        reference="chains"
        validate={[required()]}
      >
        <SelectInput />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export default ShopEdit;
