export default {
  en: {
    ra: {
      resources: {
        items: {
          name: "Item |||| Items",
          fields: {
            expires: "Expiry Date",
            createdate: "Created Date",
            section: "Section",
          },
        },
      },

      message: {
        loading: "Fetching the items, just a moment please",
      },
    },

    // myRa: {
    //   sort: {
    //     sort_by: "Sorted by %{field} %{order}",
    //   },
    // },
  },
};
