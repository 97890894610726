import React, { useEffect } from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  EditButton,
  DeleteButton,
} from "react-admin";

import { ColorField } from "react-admin-color-input";

const SectionList = ({ permissions, ...props }) => {
  useEffect(() => {
    // Update the document title using the browser API
    document.title = `ExpiryTracker - Sections`;
  });

  // Custom claims filtering
  // shopId is initially set to "x" because setting it to an empty string would couse the filter to fail
  let permanentFilter = { shopId: "x" };
  if (permissions && permissions.shopId && permissions.role === "moderator") {
    permanentFilter.shopId = permissions.shopId;
  }
  if (permissions && permissions.chainId && permissions.role === "admin") {
    delete permanentFilter.shopId;
    permanentFilter.chainId = permissions.chainId;
  }

  return (
    <List bulkActionButtons={false} filter={permanentFilter} {...props}>
      <Datagrid rowClick="edit">
        {permissions && permissions.role === "admin" ? (
          <ReferenceField
            label="Shop"
            source="shopId"
            reference="shops"
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
        ) : null}
        <TextField source="name" />
        <ColorField source="color" />
        <DateField label="Added" source="createdate" />
        <TextField source="id" />
        <EditButton basePath="/sections" />
        <DeleteButton basePath="/sections" />
      </Datagrid>
    </List>
  );
};

export default SectionList;
