import React from "react";
import {
  Edit,
  FormWithRedirect,
  TextInput,
  DateInput,
  ImageInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  required,
  minLength,
  maxLength,
  SaveButton,
  DeleteButton,
} from "react-admin";
import { formatRecord } from "../../utils";
import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery, Box, Toolbar, Typography } from "@material-ui/core";

import TimeStatuses from "../TimeStatuses";

const ColoredDateInput = (props) => {
  const theme = useTheme();
  return (
    <DateInput
      {...props}
      inputProps={{
        style: {
          backgroundColor: formatRecord(
            props.record,
            {
              expired: theme.palette.error.main,
              expiring: theme.palette.warning.main,
            },
            theme.warnBeforeNumberOfDays
          ),
        },
      }}
    />
  );
};
// Ensure the original component defaultProps are still applied as they may be used by its parents (such as the `Show` component)
ColoredDateInput.defaultProps = DateInput.defaultProps;

const ImagePreview = ({ record, source }) => {
  const imageUrl = record[source];
  return (
    <div style={{ minHeight: 90, minWidth: 90, textAlign: "center" }}>
      <img alt={record.name} src={imageUrl} width="90" height="90" />
    </div>
  );
};

const ItemEdit = ({ permissions, ...props }) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  let updatedbyname = "";
  if (permissions && permissions.name) {
    updatedbyname = permissions.name;
  }
  const transform = (data) => ({
    ...data,
    expires: dateParser(data.expires),
    updatedbyname,
  });

  // Custom claims filtering for sections
  // shopId is initially set to "x" because setting it to an empty string would couse the filter to fail
  let shopId = "x";
  if (permissions && permissions.shopId) {
    shopId = permissions.shopId;
  }

  const dateParser = (v) => {
    // v is a string of "YYYY-MM-DD" format
    const match = /(\d{4})-(\d{2})-(\d{2})/.exec(v);
    if (match === null) return;
    const d = new Date(match[1], parseInt(match[2], 10) - 1, match[3]);
    if (isNaN(d)) return;
    return d;
  };

  const noteParser = (v) => {
    if (v === "") {
      return "";
    }
    return v;
  };

  return (
    <Edit transform={transform} title="Edit Item" {...props}>
      <FormWithRedirect
        {...props}
        render={(formProps) => (
          <form id="edit-form">
            <Typography variant="h2">Edit the item</Typography>
            <TimeStatuses />

            <Box id="itemImageDiv">
              <ImageInput
                label="Image"
                variant="outlined"
                resource="items"
                source="image"
                accept="image/*"
                multiple={false}
                maxSize={8000000}
                labelSingle="select image"
              >
                <ImagePreview source="src" />
              </ImageInput>
            </Box>
            <Box id="itemNameDiv">
              <TextInput
                label="Name"
                InputLabelProps={{ className: "MuiInputLabel-shrink" }}
                variant="outlined"
                multiline
                resource="items"
                source="name"
                validate={[required(), minLength(2), maxLength(60)]}
                fullWidth
              />
            </Box>
            <Box id="itemExpiryAndSectionDiv" display="flex">
              <Box flex={1} mr=".5rem">
                <DateInput
                  label="Expiry date"
                  variant="outlined"
                  source="expires"
                  resource="items"
                  validate={[required()]}
                  fullWidth={isSmall}
                />
              </Box>
              <Box flex={1} style={{ textAlign: "right" }}>
                <ReferenceInput
                  InputLabelProps={{ className: "MuiInputLabel-shrink" }}
                  label="Section"
                  allowEmpty
                  source="section"
                  resource="items"
                  reference="sections"
                  filter={{ shopId }}
                >
                  <SelectInput
                    variant="outlined"
                    allowEmpty
                    optionText="name"
                  />
                </ReferenceInput>
              </Box>
            </Box>

            <Box id="itemNoteDiv">
              <TextInput
                label="Note"
                parse={noteParser}
                InputLabelProps={{ className: "MuiInputLabel-shrink" }}
                variant="outlined"
                multiline
                rows="2"
                resettable
                source="note"
                resource="items"
                validate={[maxLength(100)]}
                fullWidth
              />
            </Box>
            <Box id="itemSoldAndReducedDiv" display="flex">
              <Box flex={1} mr=".5rem">
                <BooleanInput label="Sold" source="sold" />
              </Box>
              <Box flex={3}>
                <BooleanInput label="Reduced" source="reduced" />
              </Box>
            </Box>

            <Toolbar disableGutters>
              <Box display="flex" justifyContent="space-between" width="100%">
                <SaveButton
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                />
                <DeleteButton
                  id="itemEditDeleteButton"
                  record={formProps.record}
                />
              </Box>
            </Toolbar>
          </form>
        )}
      />
    </Edit>
  );
};

export default ItemEdit;
