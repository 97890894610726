export default (previousState = { tab: 3 }, { type, payload }) => {
  if (type === "TAB_SET") {
    return { tab: payload.tab };
  }
  if (type === "ET/GET_SHOP_NAME_START") {
    return previousState;
  }
  if (type === "ET/GET_SHOP_NAME_FETCH") {
    return {
      ...previousState,
      shopName: payload.shopName,
    };
  }

  return previousState;
};
