import React from "react";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import { DateField } from "react-admin";
import { daysLeft } from "../utils";
import { useTheme } from "@material-ui/core/styles";

export default function ExpiryLinearProgress({ record }) {
  const theme = useTheme();
  const progressHundredPercent = theme.progressHundredPercent || 365;
  const daysLeftCorrected =
    daysLeft(record.expires) >= progressHundredPercent
      ? progressHundredPercent
      : daysLeft(record.expires);

  return (
    <div>
      {record &&
        daysLeft(record.expires) < progressHundredPercent + 1 &&
        daysLeft(record.expires) > 0 && (
          <LinearProgress
            variant="determinate"
            value={daysLeftCorrected * (1 / progressHundredPercent) * 100}
            color="primary"
            aria-label={`${daysLeft(record.expires)} days left`}
          />
        )}
      <Grid container justify="space-between">
        <Grid item>
          <Typography
            variant="caption"
            color={daysLeft(record.expires) < 1 ? "error" : "initial"}
          >
            {daysLeft(record.expires) < 0 ? "Expired " : null}
            <b>{Math.abs(daysLeft(record.expires))}</b> day
            {daysLeft(record.expires) !== 1 && daysLeft(record.expires) !== -1
              ? "s"
              : null}
            {daysLeft(record.expires) < 0 ? " ago" : " left"}
          </Typography>
        </Grid>
        <Grid item>
          <DateField
            variant="caption"
            record={record}
            source="expires"
            options={{
              year: "numeric",
              month: "long",
              day: "numeric",
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
