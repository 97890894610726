import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
  required,
} from "react-admin";
import randomstring from "randomstring";

const transform = (data) => {
  const dataName = data.name;
  const shopId =
    encodeURIComponent(dataName.replaceAll(" ", "-").toLowerCase()) +
    "-" +
    randomstring.generate(7);
  return {
    ...data,
    id: shopId,
  };
};

const ChainCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = ({ data }) => {
    notify(`New chain "${data.name}" added.`);
    redirect("/chains");
  };

  return (
    <Create
      title="Add Chain"
      onSuccess={onSuccess}
      transform={transform}
      {...props}
    >
      <SimpleForm variant="standard">
        <TextInput label="Name" source="name" validate={[required()]} />
        <TextInput multiline label="Note" source="note" />
      </SimpleForm>
    </Create>
  );
};

export default ChainCreate;
