import React from "react";
import { useRecordContext, BooleanField } from "react-admin";
const SoldWrapper = (props) => {
  const record = useRecordContext(props);
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };
  if (!record || !record.sold) return <BooleanField source="sold" />;
  return (
    <>
      <BooleanField source="sold" />
      <span>
        {record.soldat
          ? record.soldat.toLocaleDateString(undefined, options)
          : "unknown"}{" "}
        by {record.soldbyname}
      </span>
    </>
  );
};

export default SoldWrapper;
