import React, { useEffect } from "react";
import {
  Edit,
  TextInput,
  BooleanInput,
  CheckboxGroupInput,
  // SelectInput,
  SimpleForm,
} from "react-admin";

const ProfileEdit = ({ staticContext, ...props }) => {
  useEffect(() => {
    // Update the document title using the browser API
    document.title = `ExpiryTracker - Profile`;
  });
  return (
    <Edit
      /*
                As we are not coming for a route generated by a Resource component,
                we have to provide the id ourselves.
                As there is only one config for the current user, we decided to
                hardcode it here
            */
      id="my-profile"
      /*
                For the same reason, we need to provide the resource and basePath props
                which are required by the Edit component
            */
      resource="profile"
      basePath="/my-profile"
      redirect={false}
      title="My profile"
      {...props}
    >
      <SimpleForm variant="standard">
        <TextInput source="nickname" />
        {/* <SelectInput
          label="Language"
          source="language"
          choices={[
            { id: "en", name: "English" },
            { id: "hr", name: "Hrvatski" },
          ]}
        /> */}
        <TextInput label="Email for daily reports" source="emailForReports" />
        <BooleanInput label="Receive reports by email" source="receiveEmail" />
        <CheckboxGroupInput
          source="weekdays"
          label="Choose when you want to receive email reports. The mail is sent at 7 am, GMT."
          choices={[
            { id: 0, name: "Sunday" },
            { id: 1, name: "Monday" },
            { id: 2, name: "Tuesday" },
            { id: 3, name: "Wednesday" },
            { id: 4, name: "Thursday" },
            { id: 5, name: "Friday" },
            { id: 6, name: "Saturday" },
          ]}
        />
      </SimpleForm>
    </Edit>
  );
};

export default ProfileEdit;
