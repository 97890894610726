import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
  required,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import randomstring from "randomstring";

const transform = (data) => {
  const dataName = data.name;
  const shopId =
    encodeURIComponent(dataName.replaceAll(" ", "-").toLowerCase()) +
    "-" +
    randomstring.generate(7);
  return {
    ...data,
    id: shopId,
  };
};

const ShopCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = ({ data }) => {
    notify(`New shop "${data.name}" added.`);
    redirect("/shops");
  };

  return (
    <Create
      title="Add Shop"
      onSuccess={onSuccess}
      transform={transform}
      {...props}
    >
      <SimpleForm variant="standard">
        <TextInput label="Name" source="name" validate={[required()]} />
        <TextInput multiline label="Address" source="address" />
        <ReferenceInput
          label="Chain"
          source="chainId"
          reference="chains"
          validate={[required()]}
        >
          <SelectInput />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export default ShopCreate;
