import React, { createElement, useRef, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Card, Avatar, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import LockIcon from "@material-ui/icons/Lock";
import { useHistory } from "react-router-dom";
import { useCheckAuth } from "ra-core";
import logo from "../logo/logo.svg";
import { useMediaQuery } from "@material-ui/core";

import defaultTheme from "../theme";
import { createMuiTheme } from "@material-ui/core/styles";

import DefaultNotification from "./Notification";
import DefaultLoginForm from "./LoginForm";

const useStyles = makeStyles(
  (theme) => ({
    main: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      alignItems: "center",
      justifyContent: "space-between",
      backgroundColor: "#f3f2ef",
    },
    card: {
      minWidth: 300,
      marginTop: "2rem",
      [theme.breakpoints.down("sm")]: {
        marginTop: "1.5rem",
      },
    },
    ad: {
      minWidth: 300,
      maxWidth: 600,
      padding: "1rem",
      lineHeight: "1.5",
      // textAlign: "center"
    },
    etIllustration: {
      width: "70%",
      maxWidth: 550,
      margin: "0 auto",
      display: "block",
    },
    etLogo: {
      marginTop: 30,
      marginBottom: 10,
      width: 150,
      [theme.breakpoints.down("sm")]: {
        marginBottom: 5,
        width: 130,
        marginTop: 18,
      },
    },
    paraf: {
      marginBottom: "1rem",
      fontSize: "1rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: ".9rem",
      },
    },
    button: {
      margin: "2rem auto 1rem",
      [theme.breakpoints.down("sm")]: {
        margin: "1rem auto 1rem",
      },
      fontSize: ".8rem",
      display: "block",
      width: 160,
      textAlign: "center",
    },
    footer: {
      borderTop: "1px solid rgba(0,0,0,.1)",
      marginBottom: ".7rem",
      paddingTop: ".4rem",
      fontSize: 13,
      width: "100%",
      display: "flex",
      justifyContent: "center",
      color: "rgba(0,0,0,.6)",
    },
    footerLink: {
      color: "rgba(0,0,0,.6)",
      textDecoration: "none",
    },
    ijLogo: { paddingRight: ".5rem", opacity: ".8" },
    avatar: {
      margin: "1em",
      display: "flex",
      justifyContent: "center",
    },
    icon: {
      backgroundColor: theme.palette.secondary[500],
    },
  }),
  { name: "RaLogin" }
);

const Login = (props) => {
  const {
    theme,
    title,
    classes: classesOverride,
    className,
    children,
    notification,
    staticContext,
    backgroundImage,
    ...rest
  } = props;
  const containerRef = useRef();
  const classes = useStyles(props);
  const muiTheme = useMemo(() => createMuiTheme(theme), [theme]);
  let backgroundImageLoaded = false;
  const checkAuth = useCheckAuth();
  const history = useHistory();
  const isSmall = useMediaQuery((defaultTheme) => theme.breakpoints.down("sm"));
  useEffect(() => {
    checkAuth({}, false)
      .then(() => {
        // already authenticated, redirect to the home page
        history.push("/");
      })
      .catch(() => {
        // not authenticated, stay on the login page
      });
  }, [checkAuth, history]);

  const updateBackgroundImage = () => {
    if (!backgroundImageLoaded && containerRef.current) {
      containerRef.current.style.backgroundImage = `url(${backgroundImage})`;
      backgroundImageLoaded = true;
    }
  };

  // Load background image asynchronously to speed up time to interactive
  const lazyLoadBackgroundImage = () => {
    if (backgroundImage) {
      const img = new Image();
      img.onload = updateBackgroundImage;
      img.src = backgroundImage;
    }
  };

  useEffect(() => {
    if (!backgroundImageLoaded) {
      lazyLoadBackgroundImage();
    }
  });

  return (
    <ThemeProvider theme={muiTheme}>
      <div
        className={classnames(classes.main, className)}
        {...rest}
        ref={containerRef}
      >
        <Card className={classes.card}>
          {!isSmall && (
            <div className={classes.avatar}>
              <Avatar className={classes.icon}>
                <LockIcon />
              </Avatar>
            </div>
          )}
          {children}
        </Card>
        <div className={classes.ad}>
          <img
            className={classes.etIllustration}
            src="/expirytracker.png"
            alt="ExpiryTracker"
          />
          <img className={classes.etLogo} src={logo} alt="logo"></img>
          <p className={classes.paraf}>
            A SaaS single-page web app aimed at retail shops. It both reduces
            the time spent on date-checking and makes the task more efficient.
          </p>
          <p className={classes.paraf}>
            It's built using React.js on the frontend, and Google Firebase on
            the backend.
          </p>
          <Button
            className={classes.button}
            variant="contained"
            href="mailto:igorjelen@gmail.com"
          >
            Request a demo
          </Button>
        </div>
        <div className={classes.footer}>
          <img
            src="/ij-logo.png"
            alt=""
            width="17"
            className={classes.ijLogo}
          />
          <p>
            © 2021{" "}
            <a className={classes.footerLink} href="http://www.igorjelen.com/">
              igorjelen.com
            </a>
          </p>
        </div>
        {notification ? createElement(notification) : null}
      </div>
    </ThemeProvider>
  );
};

Login.propTypes = {
  backgroundImage: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  theme: PropTypes.object,
  staticContext: PropTypes.object,
};

Login.defaultProps = {
  theme: defaultTheme,
  children: <DefaultLoginForm />,
  notification: DefaultNotification,
  // backgroundImage: "https://source.unsplash.com/random/1600x900/daily",
};

export default Login;
