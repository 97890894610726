import React, { useEffect } from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  DeleteButton,
} from "react-admin";

const ChainList = (props) => {
  useEffect(() => {
    // Update the document title using the browser API
    document.title = `ExpiryTracker - Chains`;
  });
  return (
    <List bulkActionButtons={false} {...props}>
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <TextField source="note" />
        <DateField label="Created" source="createdate" />
        <TextField source="id" />
        <EditButton basePath="/chains" />
        <DeleteButton basePath="/chains" />
      </Datagrid>
    </List>
  );
};

export default ChainList;
