import React, { Component } from "react";
import { connect } from "react-redux";
import { crudGetOne, UserMenu, MenuItemLink } from "react-admin";
import SettingsIcon from "@material-ui/icons/Settings";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles({
  avatar: {
    height: 22,
    width: 22,
    filter: "grayscale(50%)",
  },
});
const MyCustomIcon = ({ profile }) => {
  const classes = useStyles();
  return (
    <Avatar
      className={classes.avatar}
      src={
        profile && profile.nickname
          ? `https://avatars.dicebear.com/api/initials/${profile.nickname.charAt(
              0
            )}.svg`
          : null
      }
    />
  );
};

class MyUserMenuView extends Component {
  componentDidMount() {
    this.fetchProfile();
  }

  fetchProfile = () => {
    this.props.crudGetOne(
      // The resource
      "profile",
      // The id of the resource item to fetch
      "my-profile",
      // The base path. Mainly used on failure to fetch the data
      "/my-profile",
      // Wether to refresh the current view. I don't need it here
      false
    );
  };

  render() {
    const { crudGetOne, profile, ...props } = this.props;
    return (
      <UserMenu
        label={profile ? profile.nickname : ""}
        {...props}
        icon={<MyCustomIcon profile={profile} />}
      >
        <MenuItemLink
          to="/my-profile"
          primaryText="My profile"
          leftIcon={<SettingsIcon />}
        />
      </UserMenu>
    );
  }
}

const mapStateToProps = (state) => {
  const resource = "profile";
  const id = "my-profile";

  return {
    profile: state.admin.resources[resource]
      ? state.admin.resources[resource].data[id]
      : null,
  };
};

const MyUserMenu = connect(mapStateToProps, { crudGetOne })(MyUserMenuView);
export default MyUserMenu;
