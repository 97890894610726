import React from "react";
import { Box, Toolbar, useMediaQuery, Typography } from "@material-ui/core";
import {
  Create,
  useNotify,
  useRedirect,
  FormWithRedirect,
  TextInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  ImageInput,
  required,
  minLength,
  maxLength,
  SaveButton,
} from "react-admin";
import { analytics } from "./../../firebaseProviders";

const ImagePreview = ({ record, source }) => {
  const imageUrl = record[source];
  return (
    <div style={{ minHeight: 90, minWidth: 90, textAlign: "center" }}>
      <img alt={record.name} src={imageUrl} width="90" height="90" />
    </div>
  );
};

const ItemCreate = ({ permissions, ...props }) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = ({ data }) => {
    analytics.logEvent("item_created", {
      name: data.name,
      expires: data.expires,
    });
    notify(`New item "${data.name}" added.`);
    redirect("/items?filter={}&order=DESC&page=1&perPage=10&sort=createdate");
  };
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  let shopId = "Error";
  if (permissions && permissions.shopId) {
    shopId = permissions.shopId;
  }
  let createdbyname = "Anonymus";
  if (permissions && permissions.name) {
    createdbyname = permissions.name;
  }
  let chainId = "Error";
  if (permissions && permissions.chainId) {
    chainId = permissions.chainId;
  }
  const transform = (data) => ({
    ...data,
    shopId,
    chainId,
    expires: dateParser(data.expires),
    createdbyname,
    sold: false,
    reduced: false,
  });

  // https://marmelab.com/react-admin/Inputs.html#recipes
  // Transforming Input Value to/from Record
  // Used with transform instead

  const dateParser = (v) => {
    // v is a string of "YYYY-MM-DD" format
    const match = /(\d{4})-(\d{2})-(\d{2})/.exec(v);
    if (match === null) return;
    const d = new Date(match[1], parseInt(match[2], 10) - 1, match[3]);
    if (isNaN(d)) return;
    return d;
  };

  return (
    <Create
      transform={transform}
      onSuccess={onSuccess}
      title="Add Item"
      {...props}
    >
      <FormWithRedirect
        {...props}
        render={(formProps) => (
          <form id="create-form">
            <Typography variant="h2">Create an item</Typography>
            <Box id="itemImageDiv">
              <ImageInput
                label="Image"
                variant="outlined"
                resource="items"
                source="image"
                accept="image/*"
                multiple={false}
                maxSize={8000000}
                labelSingle="select image"
              >
                <ImagePreview source="src" />
              </ImageInput>
            </Box>
            <Box id="itemNameDiv">
              <TextInput
                label="Name"
                InputLabelProps={{ className: "MuiInputLabel-shrink" }}
                variant="outlined"
                multiline
                resource="items"
                source="name"
                validate={[required(), minLength(2), maxLength(60)]}
                fullWidth
              />
            </Box>
            <Box id="itemExpiryAndSectionDiv" display="flex">
              <Box flex={1} mr=".5rem">
                <DateInput
                  label="Expiry date"
                  variant="outlined"
                  source="expires"
                  resource="items"
                  validate={[required()]}
                  fullWidth={isSmall}
                />
              </Box>
              <Box flex={1} style={{ textAlign: "right" }}>
                <ReferenceInput
                  InputLabelProps={{ className: "MuiInputLabel-shrink" }}
                  label="Section"
                  allowEmpty
                  source="section"
                  resource="items"
                  reference="sections"
                  filter={{ shopId }}
                >
                  <SelectInput
                    variant="outlined"
                    allowEmpty
                    optionText="name"
                  />
                </ReferenceInput>
              </Box>
            </Box>

            <Box id="itemNoteDiv">
              <TextInput
                label="Note"
                InputLabelProps={{ className: "MuiInputLabel-shrink" }}
                variant="outlined"
                multiline
                rows="2"
                resettable
                source="note"
                resource="items"
                validate={[maxLength(100)]}
                fullWidth
              />
            </Box>

            <Toolbar disableGutters>
              {/* TODO: Clean up */}
              <Box display="flex" justifyContent="space-between" width="100%">
                <SaveButton
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                />
              </Box>
            </Toolbar>
          </form>
        )}
      />
    </Create>
  );
};

export default ItemCreate;
