import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import { ColorInput } from "react-admin-color-input";

const SectionEdit = ({ permissions, ...props }) => (
  <Edit {...props}>
    <SimpleForm variant="outlined">
      {permissions && permissions.role === "admin" ? (
        <ReferenceInput label="Shop" source="shopId" reference="shops">
          <SelectInput />
        </ReferenceInput>
      ) : null}
      <TextInput disabled source="id" />
      <TextInput source="name" />
      <ColorInput source="color" />
    </SimpleForm>
  </Edit>
);

export default SectionEdit;
