import React, { useEffect } from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  DeleteButton,
  ReferenceField,
} from "react-admin";

const ShopList = (props) => {
  useEffect(() => {
    // Update the document title using the browser API
    document.title = `ExpiryTracker - Shops`;
  });
  return (
    <List bulkActionButtons={false} {...props}>
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <TextField source="address" />
        <ReferenceField
          label="Chain"
          source="chainId"
          reference="chains"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <DateField label="Created" source="createdate" />
        <TextField source="id" />
        <EditButton basePath="/shops" />
        <DeleteButton basePath="/shops" />
      </Datagrid>
    </List>
  );
};

export default ShopList;
