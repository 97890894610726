import React, { useEffect } from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  ReferenceField,
} from "react-admin";

const UserList = (props) => {
  useEffect(() => {
    // Update the document title using the browser API
    document.title = `ExpiryTracker - Users`;
  });
  return (
    <List bulkActionButtons={false} {...props}>
      <Datagrid>
        <TextField label="Name" source="customClaims.name" />
        <TextField label="Username" source="email" />
        <TextField label="Role" source="customClaims.role" />
        <DateField source="createdAt" />
        <ReferenceField
          label="Chain"
          source="customClaims.chainId"
          reference="chains"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Shop"
          source="customClaims.shopId"
          reference="shops"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <EditButton basePath="/users" />
      </Datagrid>
    </List>
  );
};

export default UserList;
