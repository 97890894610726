import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  useNotify,
  useRedirect,
  required,
} from "react-admin";
import { ColorInput } from "react-admin-color-input";
import randomstring from "randomstring";

let permissionsGlobal;

const transform = (data) => {
  const dataName = data.name;
  const sectionId =
    encodeURIComponent(dataName.replaceAll(" ", "-").toLowerCase()) +
    "-" +
    randomstring.generate(7);
  if (
    permissionsGlobal &&
    permissionsGlobal.shopId &&
    permissionsGlobal.role === "moderator"
  ) {
    // for a moderator extract shopId from user custom claims
    return {
      ...data,
      shopId: permissionsGlobal.shopId,
      id: sectionId,
    };
  } else {
    // UPDATE: Admin doesn't have access to section administration
    // for an admin use shopId from the form
    return {
      ...data,
      id: sectionId,
    };
  }
};

const SectionCreate = ({ permissions, ...props }) => {
  permissionsGlobal = permissions;
  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = ({ data }) => {
    notify(`New section "${data.name}" added.`);
    redirect("/sections");
  };

  return (
    <Create
      title="Add Section"
      onSuccess={onSuccess}
      transform={transform}
      {...props}
    >
      <SimpleForm variant="outlined">
        {permissions && permissions.role === "admin" ? (
          <ReferenceInput label="Shop" source="shopId" reference="shops">
            <SelectInput />
          </ReferenceInput>
        ) : null}
        <TextInput label="Name" source="name" validate={[required()]} />
        <ColorInput source="color" />
      </SimpleForm>
    </Create>
  );
};

export default SectionCreate;
