import React from "react";
import { useRecordContext } from "react-admin";
const UpdatedWrapper = (props) => {
  const record = useRecordContext(props);
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };
  if (!record || !record.updatedbyname) return null;
  return (
    <span>
      {record.lastupdate
        ? record.lastupdate.toLocaleDateString(undefined, options)
        : "unknown"}{" "}
      by {record.updatedbyname}
    </span>
  );
};

export default UpdatedWrapper;
