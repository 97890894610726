import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  typography: {
    h2: {
      fontSize: "18px",
      marginBottom: 4,
      borderBottom: "1px solid #ccc",
    },
    caption: {
      // textTransform: "uppercase",
      fontSize: 12,
    },
    subtitle2: { fontSize: ".75rem" },
    subtitle1: {
      marginTop: "1rem",
      lineHeight: "1.35",
      fontWeight: 700,
      color: "rgba(0,0,0,.7)",
      fontSize: "1.1rem",
    },
  },
  warnBeforeNumberOfDays: 10,
  defaultImage: "/logo512.png",
  progressHundredPercent: 10,
  palette: {
    secondary: {
      main: "#f5f5f5",
      contrastText: "#444",
    },
  },
  sidebar: {
    width: 240, // The default value is 240
    closedWidth: 110, // The default value is 55
  },
});
