import React from "react";
import { useRecordContext } from "react-admin";
import { daysLeft } from "../utils";

const ExpiresWrapper = (props) => {
  const record = useRecordContext(props);
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };
  if (!record) return null;
  return (
    <>
      <p style={{ fontWeight: "bold" }}>
        {record.expires
          ? record.expires.toLocaleDateString(undefined, options)
          : "unknown"}
      </p>
      <p>
        ({daysLeft(record.expires) < 0 ? "Expired " : null}
        {Math.abs(daysLeft(record.expires))} day
        {daysLeft(record.expires) !== 1 && daysLeft(record.expires) !== -1
          ? "s"
          : null}
        {daysLeft(record.expires) < 0 ? " ago" : " left"})
      </p>
    </>
  );
};

export default ExpiresWrapper;
