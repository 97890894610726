import React from "react";
import { useRecordContext, BooleanField } from "react-admin";
const ReducedWrapper = (props) => {
  const record = useRecordContext(props);
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };
  if (!record || !record.reduced) return <BooleanField source="reduced" />;
  return (
    <>
      <BooleanField source="reduced" />
      <span>
        {record.reducedat
          ? record.reducedat.toLocaleDateString(undefined, options)
          : "unknown"}{" "}
        {record.reducedbyname ? `by ${record.reducedbyname}` : null}
      </span>
    </>
  );
};

export default ReducedWrapper;
