import * as React from "react";
import { useQuery } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
// import { useDispatch } from "react-redux";

const styles = {
  shop: {
    fontSize: 12,
    marginLeft: 5,
    paddingTop: 3,
  },
  shopName: {
    textTransform: "capitalize",
  },
};

const ShopName = ({ classes, chainId, shopId, role }) => {
  // Use useEffect
  // const dispatch = useDispatch();
  // dispatch({ type: "ET/GET_SHOP_NAME_START" });
  const { data, loading, error } = useQuery({
    type: "getOne",
    resource: "shops",
    payload: { id: shopId },
  });
  // if (data && data.name) {
  //   dispatch({
  //     type: "ET/GET_SHOP_NAME_FETCH",
  //     payload: { shopName: data.name },
  //   });
  // }
  if (loading) return null;
  if (error) {
    // console.log(error);
    return null;
  }
  if (!data) return null;

  return (
    <span className={classes.shop}>
      {" "}
      for{" "}
      <span className={classes.shopName}>
        {role === "dev" || role === "admin" ? chainId.slice(0, -8) : data.name}{" "}
        {/* ({role}) */}
      </span>
    </span>
  );
};
export default withStyles(styles)(ShopName);
