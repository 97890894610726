import React, { useEffect, cloneElement } from "react";
import {
  List,
  useListContext,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  DeleteButton,
  Filter,
  SearchInput,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  TopToolbar,
  SortButton,
  CreateButton,
  ExportButton,
  useDataProvider,
  useRefresh,
  useRedirect,
} from "react-admin";
import { useMediaQuery } from "@material-ui/core";
import "@fontsource/roboto";
import { List as MuiList } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import AvatarWrapper from "../AvatarWrapper";
import Divider from "@material-ui/core/Divider";
import ExpiryLinearProgress from "../ExpiryLinearProgress";
import UpdatedWrapper from "../UpdatedWrapper";
import SectionWrapper from "../SectionWrapper";
import SoldWrapper from "../SoldWrapper";
import ReducedWrapper from "../ReducedWrapper";
import ExpiresWrapper from "../ExpiresWrapper";
import TimeStatuses from "../TimeStatuses";
import FilterTabs from "./FilterTabs";

import Typography from "@material-ui/core/Typography";
// import SortButton from "../SortButton";

import { formatRecord } from "../../utils";
import { useTheme } from "@material-ui/core/styles";

import {
  SwipeableList,
  SwipeableListItem,
} from "@sandstreamdev/react-swipeable-list";
import "@sandstreamdev/react-swipeable-list/dist/styles.css";

import { useDispatch, useSelector } from "react-redux";

import firebase from "firebase/app";

const SectionField = ({ record, className }) => {
  let sectionStyle = { backgroundColor: "white" };
  if (record && record.color) {
    sectionStyle = {
      borderLeftWidth: 3,
      borderLeftColor: record.color,
      borderLeftStyle: "solid",
    };
  }
  return (
    <span className={className} style={sectionStyle}>
      {record && record.name}
    </span>
  );
};

const MySimpleList = ({ permissions, ...props }) => {
  // eslint-disable-next-line
  const { ids, data, basePath, currentSort } = useListContext();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const dispatch = useDispatch();
  const tabValue = useSelector((state) => state.eT.tab);
  if (!data || !ids || !ids.length) return null;
  if (ids.some((id) => !data[id])) return null;

  // Get the number of properties in the data object
  // If there's only one, redirect to "All"
  function checkIfLastAndRefresh(tab = 3) {
    const propertiesCount = Object.keys(data).length;
    if (propertiesCount === 1 && tabValue === 0) {
      // Last item removed on expiring tab
      // Tab 3 is "All"
      dispatch({ type: "TAB_SET", payload: { tab } });
      redirect("/items?filter={}&order=DESC&page=1&perPage=10&sort=createdate");
    }
    refresh();
  }

  return (
    <>
      <FilterTabs permissions={permissions} />
      <MuiList>
        <SwipeableList>
          {ids.map((id, index) => (
            <SwipeableListItem
              key={id}
              swipeLeft={{
                content: <div className={"swipe-left"}>Sold</div>,
                // actionAnimation: "remove",
                action: () => {
                  const updatedItem = { ...data[id] };
                  updatedItem.sold = true;
                  // Do it on the server
                  updatedItem.soldat =
                    firebase.firestore.FieldValue.serverTimestamp();
                  updatedItem.soldby = permissions.email;
                  updatedItem.soldbyname = permissions.name;
                  dataProvider
                    .update("items", {
                      id: id,
                      data: updatedItem,
                      previousData: data[id],
                    })
                    .then(({ data }) => {
                      checkIfLastAndRefresh(3);
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                },
              }}
              swipeRight={{
                content: <div className={"swipe-right"}>Reduced</div>,
                action: () => {
                  const updatedItem = { ...data[id] };

                  if (updatedItem.reduced === true) return;
                  updatedItem.reduced = true;
                  // Do it on the server
                  updatedItem.reducedat =
                    firebase.firestore.FieldValue.serverTimestamp();
                  updatedItem.reducedby = permissions.email;
                  updatedItem.reducedbyname = permissions.name;
                  dataProvider
                    .update("items", {
                      id: id,
                      data: updatedItem,
                      previousData: data[id],
                    })
                    .then(({ data }) => {
                      checkIfLastAndRefresh(3);
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                },
              }}
              blockSwipe={data?.[id]?.sold}
              // onSwipeProgress={(progress) =>
              //   console.info(`Swipe progress: ${progress}%`)
              // }
            >
              <ListItem
                button
                component="a"
                href={`#${basePath}/${id}`}
                style={{
                  borderTop: "2px solid rgba(0, 0, 0, 0.07)",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.11)",
                  position: "relative",
                }}
              >
                <ListItemAvatar>
                  <AvatarWrapper
                    record={data[id]}
                    source="image"
                    title="image.Image"
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <div>
                        <Typography variant="subtitle1" component="h2">
                          {data[id].name}
                        </Typography>

                        {/* Ribbons */}
                        {data[id].sold ? (
                          <div className="ribbon sold ribbon-top-left">
                            <span>sold</span>
                          </div>
                        ) : null}
                        {data[id].reduced ? (
                          <div className="ribbon reduced ribbon-top-left">
                            <span>reduced</span>
                          </div>
                        ) : null}

                        {data[id].section ? (
                          <ReferenceField
                            source="section"
                            reference="sections"
                            record={data[id]}
                            basePath={basePath}
                            link={false}
                          >
                            <SectionField
                              className="sectionLabel"
                              source="name"
                            />
                          </ReferenceField>
                        ) : null}
                        <TimeStatuses record={data[id]} />
                        <Typography variant="subtitle2" component="p">
                          {data[id].note && `${data[id].note}`}
                        </Typography>
                      </div>
                      <div>
                        <ExpiryLinearProgress
                          style={{ marginTop: ".5rem" }}
                          record={data[id]}
                        />
                      </div>
                    </>
                  }
                />
                <Divider light={true} />
              </ListItem>
            </SwipeableListItem>
          ))}
        </SwipeableList>
      </MuiList>
    </>
  );
};

const ListActions = (props) => (
  <TopToolbar>
    {cloneElement(props.filters, { context: "button" })}
    <CreateButton basePath="/items" />
    <ExportButton />
  </TopToolbar>
);

const ListActionsSmall = (props) => (
  <TopToolbar>
    {cloneElement(props.filters, { context: "button" })}
    <SortButton fields={["createdate", "expires"]} />
    <CreateButton basePath="/items" />
  </TopToolbar>
);

const ItemFilters = ({ permissions, ...props }) => {
  const { filterValues } = useListContext();
  // Sections
  let sectionsFilter = { shopId: "x" };
  if (permissions && permissions.role !== "admin") {
    sectionsFilter = { shopId: permissions.shopId };
  } else if (filterValues && filterValues.shopId) {
    sectionsFilter = { shopId: filterValues.shopId };
  }
  // Shops
  let shopsFilter = { chainId: "x" };
  if (permissions && permissions.role === "admin") {
    shopsFilter = { chainId: permissions.chainId };
  } else if (filterValues && filterValues.chainId) {
    shopsFilter = { chainId: filterValues.chainId };
  }
  return (
    <Filter {...props}>
      {permissions && permissions.role === "dev" && (
        <ReferenceInput label="Chain" source="chainId" reference="chains">
          <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>
      )}
      {permissions &&
        (permissions.role === "admin" || permissions.role === "dev") && (
          <ReferenceInput
            label="Shop"
            source="shopId"
            reference="shops"
            filter={shopsFilter}
          >
            <SelectInput optionText="name" optionValue="id" />
          </ReferenceInput>
        )}
      <ReferenceInput
        filter={sectionsFilter}
        sort={{ field: "name", order: "ASC" }}
        label="Section"
        source="section"
        reference="sections"
      >
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <SearchInput placeholder="Search by Name" source="name" />
    </Filter>
  );
};

const ItemList = ({ permissions, ...props }) => {
  useEffect(() => {
    // Update the document title using the browser API
    document.title = `ExpiryTracker - Items`;
  });
  const theme = useTheme();
  // todo: clean up
  const postRowStyle = (record, index) => {
    return {
      borderColor: theme.palette.primary.main,
      borderLeftColor: formatRecord(
        record,
        {
          expired: theme.palette.error.main,
          expiring: theme.palette.warning.main,
          default: "transparent",
        },
        theme.warnBeforeNumberOfDays
      ),
      borderLeftWidth: "2px",
      borderLeftStyle: "solid",
    };
  };
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  // Set default values filter
  let defaultValuesFilter = {};
  if (permissions) {
    if (permissions.role === "admin" && permissions.shopId) {
      defaultValuesFilter.shopId = permissions.shopId;
    }
    if (permissions.role === "dev") {
      defaultValuesFilter.chainId = "x";
    }
  }

  // Set permanent filter
  let permanentFilter = {
    chainId: "x",
    shopId: "x",
  };
  if (permissions) {
    // role === "user" || role === "moderator"
    if (
      (permissions.role === "user" || permissions.role === "moderator") &&
      permissions.chainId &&
      permissions.shopId
    ) {
      permanentFilter.chainId = permissions.chainId;
      permanentFilter.shopId = permissions.shopId;
    }
    // role === "admin"
    if (permissions.role === "admin" && permissions.chainId) {
      permanentFilter.chainId = permissions.chainId;
      delete permanentFilter.shopId;
    }
    // role === "dev"
    if (permissions.role === "dev") {
      delete permanentFilter.chainId;
      delete permanentFilter.shopId;
    }
  }

  return (
    <List
      title="ExpiryTracker"
      empty={false}
      sort={{ field: "createdate", order: "DESC" }}
      actions={
        isSmall || (permissions && permissions.role === "user") ? (
          <ListActionsSmall />
        ) : (
          <ListActions />
        )
      }
      filterDefaultValues={defaultValuesFilter}
      filters={<ItemFilters permissions={permissions} variant="standard" />}
      filter={permanentFilter}
      {...props}
    >
      {isSmall || (permissions && permissions.role === "user") ? (
        <MySimpleList permissions={permissions} />
      ) : (
        <>
          <FilterTabs permissions={permissions} />

          <Datagrid rowStyle={postRowStyle}>
            <AvatarWrapper label="Image" source="image" sortable={false} />
            <TextField
              label="Name"
              source="name"
              style={{ fontSize: "1.07rem" }}
            />
            <ExpiresWrapper label="Expires" />

            {permissions && permissions.role === "admin" ? (
              <ReferenceField
                label="Shop"
                source="shopId"
                reference="shops"
                link={false}
              >
                <TextField source="name" />
              </ReferenceField>
            ) : null}
            {/* TODO: Use custom Section Component - color code it */}

            <ReferenceField
              label="Section"
              source="section"
              reference="sections"
              link={false}
            >
              <SectionWrapper />
            </ReferenceField>
            <DateField
              headerClassName="created"
              label="Created"
              source="createdate"
              options={{
                year: "numeric",
                month: "numeric",
                day: "numeric",
              }}
            />
            <TextField label="Author" source="createdbyname" />
            <UpdatedWrapper label="Updated" sortBy="lastupdate" />
            <SoldWrapper label="Sold" />
            <ReducedWrapper label="Reduced" />
            <TextField label="Note" source="note" />

            <EditButton basePath="/items" />
            <DeleteButton basePath="/items" />
          </Datagrid>
        </>
      )}
    </List>
  );
};

export default ItemList;
